<template>
    <div>
        <el-main>
            <el-form class="el-form-search" label-width="140px">
                <el-form-item label="店铺名称：">
                    <el-input size="small" placeholder="请输入店铺名称" v-model="searchForm.store_name"></el-input>
                </el-form-item>
                <el-form-item label="服务商姓名：">
                    <el-input size="small" placeholder="请输入服务商姓名" v-model="searchForm.facilitator_name"></el-input>
                </el-form-item>
                <el-form-item label="服务商电话：">
                    <el-input size="small" placeholder="请输入服务商电话" v-model="searchForm.facilitator_phone"></el-input>
                </el-form-item>
                <el-form-item label="关联订单号：">
                    <el-input size="small" placeholder="请输入关联订单号" v-model="searchForm.order_no"></el-input>
                </el-form-item>
                <el-form-item label="产生日期：">
                    <el-date-picker size="small" v-model="time" type="daterange" range-separator="~"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="充值消耗：">
                    <el-select v-model="searchForm.type" size="small" placeholder="请选择" clearable>
                        <el-option label="全部" value=""></el-option>
                        <el-option label="充值" :value="1"></el-option>
                        <el-option label="消耗" :value="-1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label=" " label-width="25px">
                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                    <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
                </el-form-item>
            </el-form>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="store_info" label="店铺名称" align="center" min-width="160">
                    <template v-slot="{ row }">{{ row.store_info.store_name }}</template>
                </el-table-column>
                <el-table-column prop="facilitator_info" label="所属服务商姓名" min-width="180" align="center"
                    v-if="u_id == 227 || u_id == 245 || u_id == 276 || u_id == 283">
                    <template v-slot="{ row }">{{ row.facilitator_info.name }}</template>
                </el-table-column>
                <el-table-column prop="facilitator_info" label="服务商电话" min-width="150" align="center"
                    v-if="u_id == 227 || u_id == 245 || u_id == 276 || u_id == 283">
                    <template v-slot="{ row }">{{ row.facilitator_info.mobile }}</template>
                </el-table-column>
                <el-table-column prop="register_mobile" label="充值消耗" min-width="120" align="center">
                    <template v-slot="{ row }">{{ row.type == 1 ? '充值' : '消耗' }}</template>
                </el-table-column>
                <el-table-column prop="jname" label="充值/消耗金额" min-width="180" align="center">
                    <template v-slot="{ row }">{{ row.type == 1 ? '+' : '-' }}￥{{ row.amount }}</template>
                </el-table-column>
                <el-table-column prop="order_no" label="关联订单号" min-width="240" align="center"></el-table-column>
                <el-table-column label="操作时间" min-width="180" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
                </el-table-column>
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
    </div>
</template>
  
<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
                store_name: '',
                facilitator_name: '',
                facilitator_phone: '',
                order_no: '',
                type: '',
                start_time: '',
                end_time: '',
            },
            time: '',
            u_id: localStorage.getItem('u_id')
        };
    },
    created () {
        if (this.$route.query.store_name) {
            this.searchForm.store_name = this.$route.query.store_name
        }
        this.getList();
    },
    methods: {
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        // 获取列表
        getList () {
            let searchForm = { ...this.searchForm }
            if (this.time) {
                searchForm.start_time = this.time[0] / 1000
                searchForm.end_time = this.time[1] / 1000
            }
            this.$axios.post(this.$api.serviceProvider.RechargeList, searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>
  